<template>
  <div class="content-box">
    <van-sticky>
      <title-bar2 title="在线支付" />
    </van-sticky>
    <van-cell-group title="大数据VIP会员：可解锁使用AI择校+智慧调剂+报录数据+调剂公告的全部功能和数据。">
      <van-radio-group v-model="selectedItem">
        <van-card v-for="(item, index) in productList" :key="index" :thumb="require('@/assets/images/mt/thumb.png')" :centered="false" :price="`${item.ProductPrice.toFixed(2)}`" :num="1">
          <template #title>
            <van-radio :name="item">{{ item.ProductName }}</van-radio>
          </template>
        </van-card>
      </van-radio-group>
      <van-cell title="支付方式：" value="在线支付" />
      <van-cell title="商品金额：" :value="productPrice" />
    </van-cell-group>
    <van-cell-group title="付款方式" v-if="payWay == 1 || payWay == 2">
      <van-radio-group v-model="payWay">
        <van-cell clickable @click="payWay = 1">
          <template #title>
            <span class="radio-item">
              <img src="@/assets/images/comm/pay-alipay.png" class="radio-img">
              <span class="radio-txt">支付宝支付</span>
            </span>
          </template>
          <template #right-icon>
            <van-radio :name="1" />
          </template>
        </van-cell>
        <van-cell clickable @click="payWay = 2">
          <template #title>
            <span class="radio-item">
              <img src="@/assets/images/comm/pay-wechat.png" class="radio-img">
              <span class="radio-txt">微信支付</span>
            </span>
          </template>
          <template #right-icon>
            <van-radio :name="2" />
          </template>
        </van-cell>
      </van-radio-group>
    </van-cell-group>
    <van-notice-bar wrapable :scrollable="false" left-icon="info-o">请务必仔细核对订单，确认信息无误后再支付，支付后不支持退款！</van-notice-bar>
    <van-submit-bar :disabled="disabled" :price="productPrice * 100" button-text="提交订单" @submit="onSubmit" />
  </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import Manager from "@/manager";
import Tools from "@/tool";
import Config from "@/api/service";

export default {
  name: "MTModule",
  components: {
    TitleBar2,
  },
  data() {
    return {
      disabled: false,
      isDocin: false,
      admitGuid: null,
      majorCode: null,
      selectedItem: null,
      productList: [],
      payWay: 0,
    };
  },
  computed: {
    productPrice() {
      if (this.selectedItem)
        return this.selectedItem.ProductPrice;
    }
  },
  methods: {
    //获取路由参数
    getRoutParams() {
      this.admitGuid = this.$route.query.admitGuid;
      this.majorCode = this.$route.query.majorCode;
    },
    //获取是否豆丁账户
    getIsDocin() {
      var model = {
        ProxyCode: this.$store.state.proxyCode,
      };
      return this.$axios
        .post(Config.proxy.isDocin, model)
        .then((res) => {
          this.isDocin = res.data.Data.IsDocin;
          if (!this.isDocin) {
            this.payWay = 1;
          }
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //获取商品列表
    getProductList() {
      var model = {};
      this.$axios
        .post(Config.member.list, model)
        .then((res) => {
          this.productList = res.data.Data.ProductList;
          this.selectedItem = this.productList[0];
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //提交订单
    onSubmit() {
      if (!this.$store.getters.isLogin) {
        this.$loginDialog.show(true);
        return;
      }
      if (this.selectedItem == null) {
        this.$dialog({ title: "提示", message: "请选择购买的商品", });
        return;
      }
      if (Tools.isEmpty(this.payWay)) {
        this.$toast.fail("请选择付款方式。");
        return;
      }
      this.$dialog.confirm({
        title: '订单确认',
        message: '请确认订单信息，付款后不支持退款。',
      }).then(() => {
        this.loading = true;
        var model = {
          ProxyCode: this.$store.state.proxyCode,
          ProductGuid: this.selectedItem.ProductGuid,
          TerminalType: 1,
          OrderPayForm: this.payWay,
          PayFinishUrl: Manager.goPayFinishUrl(this.$store.state.proxyCode),
        };
        this.$axios
          .post(Config.member.submitOrder, model)
          .then((res) => {
            this.$router.push({
              name: "PayBefore",
              params: {
                proxyCode: this.$store.state.proxyCode
              },
              query: {
                payUrl: res.data.Data.DocinPayUrl
              }
            });
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }).catch(() => {

      });
    }
  },
  mounted() {
    this.getRoutParams();
    this.getIsDocin();
    this.getProductList();
  }
};
</script>

<style scoped lang="less">
.content-box {
  height: calc(100% - 50px);
  overflow: auto;
}

.radio-item {
  display: flex;
  align-items: center;

  .radio-img {
    width: 20px;
  }

  .radio-txt {
    margin-left: 10px;
  }
}

.van-card__content {
  justify-content: space-around;
}
</style>